<template>
    <div class="webdownload">
        <div class="padd20 borbut20">
            <div class="topSearchSou">
                <div class="searchdan time">
                    <div class="title">下载时间：</div>
                    <el-date-picker v-model="pickerTime" @change="onPicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div>
        <div class="group-table padd20">
            <div class="duoshao">
                <div class="title">下载列表</div>
                <div class="lvtotal">
                    总条数：{{lvtotal ? lvtotal : '0'}} 条
                </div>
            </div>
            <div class="ErLoadList">
                <div class="dan" :class="item.job_status == 3 ? 'borderLv' : ''" v-for="(item,key) in ErLoadList" :key="key">
                    <div class="dowTitle">
                        <p class="name">{{item.file_name}}</p>
                        <p class="time">生成时间：{{item.created_at}}</p>
                    </div>
                    <!-- <div class="dowCategory"><el-tag type="warning">{{item.category}}</el-tag></div> -->
                    <div class="generate">
                        <div v-if="item.job_status == 1"><i class="el-icon-refresh"></i>正在排队中...</div>
                        <div v-if="item.job_status == 2"><i class="el-icon-loading"></i>正在处理中...</div>
                        <div v-if="item.job_status == 3"><i class="el-icon-success"></i>资源生成成功</div>
                        <div v-if="item.job_status == 4" style="color:#f00"><i class="el-icon-error"></i>资源处理失败</div>
                    </div>
                    <div class="downLoad">
                        <div class="dowButton" v-if="item.job_status == 3"><a :href="item.file_link" target="_blank"><i class="el-icon-download"></i>下载文件</a></div>
                        <div class="dowButton" v-else>资源未生成</div>
                        <!-- <el-button type="info" plain :disabled="false" icon="el-icon-download">下载文件</el-button> -->
                    </div>
                </div>
            </div>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            <!-- <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :current-page.sync="paramPage" hide-on-single-page></el-pagination> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            paramData: {}, //表格参数
            pickerTime: null, //开始日期结束日期
            ErLoadList: [], //下载列表
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1, //表格当前第几页
            ErLoadList: [],
            job_id: "", //循环取到的id
            fotitem: "", //定时任务
        };
    },
    mounted() {
        this.ongetExportJobList();
    },
    methods: {
        //日期选择触发
        onPicker() {
            // console.log(this.pickerTime)
        },
        //商品列表
        ongetExportJobList() {
            this.ErLoadList = [];
            var param = this.paramData;
            param.page = this.paramPage;
            this.$service.get(this.$api.getExportJobList, param, (res) => {
                if (res.code == "200") {
                    this.ErLoadList = res.data.data;
                    this.lvtotal = res.data.total;
                    res.data.data.forEach((v, k) => {
                        if (v.job_status == 2) {
                            this.job_id = v.id;
                            // console.log(this.job_id)
                            this.onForExportJob();
                        }
                    });
                }
            });
        },
        //商品搜索
        onButtonInquire() {
            this.paramData = {
                start_created_at: this.pickerTime ? this.pickerTime[0] : "", //开始日期
                end_created_at: this.pickerTime ? this.pickerTime[1] : "", //结束日期
            };
            this.paramPage = 1;
            this.ongetExportJobList();
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val;
            this.ongetExportJobList();
        },
        //循环调取
        onForExportJob() {
            this.fotitem = setInterval(() => {
                this.onwhileGetExportJob();
            }, 2000);
        },
        //鲁班商品导出
        onwhileGetExportJob() {
            // console.log(3333)
            var param = {
                job_id: this.job_id,
                deleteJiaZai: 1,
            };
            this.$service.get(this.$api.whileGetExportJob, param, (res) => {
                if (res.code == "200") {
                    if (res.data.job_status == 3) {
                        clearInterval(this.fotitem);
                        this.ongetExportJobList();
                    }
                    if (res.data == null) {
                        clearInterval(this.fotitem);
                    }
                }
            });
        },
    },
    //跳转页面前执行
    destroyed() {
        clearInterval(this.fotitem);
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.webdownload {
    .ErLoadList {
        .dan {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #f4f8fb;
            padding: 20px;
            margin-bottom: 10px;
            border-bottom: 2px solid #eee;
            &.borderLv {
                border-bottom: 2px solid #67c23a;
                .generate {
                    i {
                        color: #67c23a;
                    }
                }
            }
            .dowTitle {
                width: 35%;
                .name {
                    font-size: 18px;
                    color: #333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }
                .time {
                    color: #666;
                    margin-top: 10px;
                }
            }
            .generate {
                i {
                    margin-right: 5px;
                }
            }
        }
    }
}
</style>
<style lang="scss">
</style>